<template>
  <div class="curriculumDetails">
    <div class="media">
      <div class="title">
        {{ viewTitle }}
        <!-- <el-button v-if="currentPlayStatus" @click="onPlayerEnded" size="mini" style="position: absolute;right: 4px;top: 4px;">已学完此课程</el-button> -->
      </div>
      <!-- <div class="title2">（温馨提示：非直播视频学员需要点击暂停键才能断点续播）</div> -->
      <!-- <video-player
      v-if="videos.length || lives.length"-->
      <video-player
        class="video-player vjs-custom-skin"
        :class="{ aliving: activelives != null, 'alive-done': liveingDone }"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @playing="onPlayerPlaying($event)"
        @pause="onPlayerPause($event)"
        @timeupdate="timeupdate"
        @ended="onPlayerEnded"
      ></video-player>
    </div>
    <div class="media-list">
      <el-tabs v-model="activeTabsNames">
        <el-tab-pane label="简介" name="first">{{ description }}</el-tab-pane>
        <el-tab-pane label="课程目录" name="second">
          <div
            v-if="lives.length"
            style="margin-bottom: 20px; background: #f9f9f9; padding: 10px"
          >
            <p style="color: #677897; font-size: 16px; font-weight: bold">
              视频直播
              <span style="color: #f00; font-size: 9px">(*不计入学习进度)</span>
            </p>
            <div
              class="ailve-item"
              v-for="(i, _i) in lives"
              :key="_i"
              @click="viewIives(i, _i)"
              :class="{ active: activelives == _i }"
            >
              {{ i.living ? "【直播中】" : "【未开始】" }}{{ i.title }}
              <div class="description">{{ i.aliveDate }}</div>
            </div>
          </div>

          <div v-if="videos.length" style="background: #f9f9f9; padding: 10px">
            <p style="color: #677897; font-size: 16px; font-weight: bold">
              视频课件
            </p>
            <div class="video-item" v-for="(i, _i) in videos" :key="_i">
              <div class="video-title">{{ _i + 1 }}.{{ i.title }}</div>
              <div
                class="video-item-list"
                v-for="(s, _s) in i.coursewares"
                :key="_s"
                :class="{
                  active:
                    s.id == activeVideo && s.sectionId == actvieVideoSections,
                }"
                @click="viewViedo(s)"
              >
                <div class="main-title">
                  <i class="el-icon-video-camera-solid"></i>
                  {{ s.title }}
                </div>
                <span class="timer">
                  {{ formatterData(s.vedio.timeLength) }}
                </span>
                <p style="clear: both; font-size: 12px; line-height: 20px">
                  学习进度：{{
                    s.currentTime
                      ? "（" +
                        ((s.currentTime / s.vedio.timeLength) * 100).toFixed(
                          2
                        ) +
                        "%）"
                      : ""
                  }}{{ s.isDone ? "已完成" : "未完成" }}
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="视频直播" name="third" v-if="lives.length">
          <div
            class="ailve-item"
            v-for="(i, _i) in lives"
            :key="_i"
            @click="viewIives(i, _i)"
            :class="{ active: i.living }"
          >
            {{ i.living ? "【直播中】" : "【未开始】" }}{{ i.title }}
            <div class="description">{{ i.aliveDate }}</div>
          </div>
        </el-tab-pane>-->
      </el-tabs>
    </div>
  </div>
</template>
<script>
import moment from "moment";
let VideocurrentTime = 0;
export default {
  name: "curriculum",
  data() {
    return {
      videoId: "",
      httpsLocation: "https://tecsf.gdcoh.com",
      activePanesNames: [0],
      activeTabsNames: "second",
      viewTitle: "",
      description: "",
      videos: [],
      videoType: "",
      activeVideo: "",
      actvieVideoSections: 0,
      lives: [],
      activelives: "",
      liveingDone: false,
      currentPlayStatus: false,
      currentPlayTimer: 0,
      playerOptions: {
        //播放速度
        playbackRates: [],
        //如果true,浏览器准备好时开始回放。
        autoplay: true,
        // 默认情况下将会消除任何音频·。
        muted: false,
        // 导致视频一结束就重新开始。
        loop: false,
        // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        preload: "auto",
        language: "zh-CN",
        // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        aspectRatio: "16:9",
        // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        fluid: true,
        sources: [],
        //你的封面地址
        // poster:
        // "https://gimg2.baidu.com/image_search/src=http%3A%2F%2F1812.img.pp.sohu.com.cn%2Fimages%2Fblog%2F2009%2F11%2F18%2F18%2F8%2F125b6560a6ag214.jpg&refer=http%3A%2F%2F1812.img.pp.sohu.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1624024420&t=606e426a40c26ff068470eabe257afab",
        //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          //全屏按钮
          fullscreenToggle: true,
        },
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
      },
    };
  },
  created() {
    this.videoId = this.$route.query && this.$route.query.id;
    this.initData();
  },
  methods: {
    timeupdate(player) {
      if (this.videoType != "liveing") {
        let currentTime = player.cache_.currentTime;
        this.setVidoData(
          {
            detailsID: this.videoId,
            activeVideoID: this.activeVideo,
            time: currentTime || null,
          },
          () => {},
          true
        );
        if (!this.currentPlayTimer || !this.currentPlayStatus) {
          this.videos.forEach((el) => {
            if (el.id == this.actvieVideoSections) {
              el.coursewares.forEach((item) => {
                if (item.id == this.activeVideo && !item.isDone) {
                  this.currentPlayTimer = item.vedio.timeLength;
                  item.currentTime = currentTime;
                }
              });
            }
          });
          this.currentPlayStatus = this.currentPlayTimer
            ? currentTime / this.currentPlayTimer >= 0.8
            : false;
          if (this.currentPlayStatus) {
            this.onPlayerEnded();
          }
          VideocurrentTime = currentTime;
        }
      }
    },
    formatterData(time) {
      if (!time) {
        return "-";
      }
      const min = Math.floor(time % 3600); //分钟
      return (
        (Math.floor(time / 3600) ? Math.floor(time / 3600) + "时" : "") +
        (Math.floor(min / 60) ? Math.floor(min / 60) + "分" : "") +
        ((time % 60) + "秒")
      );
    },
    initData() {
      const list = this.$storage.getStorage("vidoDataList") || [];
      const videoHistoryMap = {};
      list.map((el) => (videoHistoryMap[el.activeVideoID] = el.time));
      const id = this.$route.query && this.$route.query.id;
      this.$https
        .get("/api/my/course/details/" + id)
        .then((res) => {
          if (res.success) {
            const data = res.data;
            let videoing = false;
            this.description = data.description;
            // 直播
            let _lives = data.lives.map((el) => {
              return {
                ...el,
                ...this.judeLives(res, el),
              };
            });
            this.lives = _lives
              .filter((el) => el.living)
              .concat(_lives.filter((el) => !el.living));
            this.lives.forEach((el, _el) => {
              if (el.living) {
                videoing = true;
                this.viewIives(el, _el);
              }
            });
            // 视频
            this.videos = data.courseSections.map((el, _el) => {
              return {
                ...el,
                coursewares: data.coursewares
                  .filter((item) => item.sectionId == el.id)
                  .map((val, _val) => {
                    val.currentTime = videoHistoryMap[val.id];
                    if (!val.isDone && !videoing) {
                      videoing = true;
                      this.viewViedo(val);
                    }
                    return val;
                  }),
              };
            });
            if (!videoing) {
              data.courseSections.map((el, _el) => {
                data.coursewares
                  .filter((item) => item.sectionId == el.id)
                  .map((val, _val) => {
                    if (!_el) {
                      videoing = true;
                      this.viewViedo(val);
                    }
                  });
              });
            }
          } else {
            this.$message.error(res.debug);
          }
        })
        .catch((error) => {
          this.$message.error(error.debug);
        });
    },
    // 播放回调
    onPlayerPlay() {
      if (this.videoType == "liveing") {
        this.$refs.videoPlayer.player.currentTime(this.currentTime);
      } else {
        this.setVidoData(
          {
            detailsID: this.videoId,
            activeVideoID: this.activeVideo,
          },
          (list, listIndex) => {
            this.$refs.videoPlayer.player.currentTime(list[listIndex].time);
          }
        );
      }
    },
    onPlayerPlaying() {
      if (!this.playerPlaying) {
        this.playerPlaying = true;
        this.onPlayerPlay();
        // this.$refs.videoPlayer.player.currentTime(this.currentTime);
      }
    },
    // 暂停回调
    onPlayerPause(player) {
      this.setVidoData(
        {
          detailsID: this.videoId,
          activeVideoID: this.activeVideo,
          time: player.cache_.currentTime,
        },
        () => {},
        true
      );
    },
    // 视频播完回调
    onPlayerEnded() {
      if (this.videoType == "liveing") {
        let target = this.lives[this.activelives];
        let url =
          target.vedios.length > target.livingIndex + 1
            ? target.vedios[target.livingIndex + 1].url
            : "";
        target.livingIndex += 1;
        if (!url) this.playerOptions.notSupportedMessage = "已经结束！";
        this.playerOptions.sources = [
          {
            type:
              (this.httpsLocation + url).indexOf(".m3u8") > 0
                ? "application/x-mpegURL"
                : "video/mp4",
            src: url ? this.httpsLocation + url : "",
          },
        ];
      } else {
        this.setVidoData(
          {
            detailsID: this.videoId,
            activeVideoID: this.activeVideo,
            time: 0,
          },
          () => {},
          true
        );
        let data = this.videos
          .find((el) => el.id == this.actvieVideoSections)
          .coursewares.find((el) => el.id == this.activeVideo);
        if (data.isDone) return;
        this.$https
          .put(
            "/api/my/course/coursewareDone/" +
              this.$route.query.id +
              "/" +
              data.id
          )
          .then((res) => {
            this.videos.forEach((el) => {
              if (el.id == this.actvieVideoSections) {
                el.coursewares.forEach((item) => {
                  if (item.id == this.activeVideo) {
                    item.isDone = true;
                  }
                });
              }
            });
          });
      }
    },
    // 直播
    viewIives(item, i) {
      this.playerPlaying = false;
      this.videoType = "liveing";
      if (!item.living) {
        this.$message({
          message: "尚未开始或已经结束",
          type: "warning",
        });
        return;
      }
      this.viewTitle = item.title;
      this.activelives = i;
      const url =
        item.vedios[item.livingIndex] && item.vedios[item.livingIndex].url;
      if (!url) this.playerOptions.notSupportedMessage = "已经结束！";
      this.playerOptions.sources = [
        {
          type:
            (this.httpsLocation + url).indexOf(".m3u8") > 0
              ? "application/x-mpegURL"
              : "video/mp4",
          src: this.httpsLocation + item.vedios[item.livingIndex].url,
        },
      ];
      let currentTime =
        item.currentTime + (moment().valueOf() - item.ajaxTime) / 1000;
      setTimeout(() => {
        this.$refs.videoPlayer.player.currentTime(currentTime);
      }, 500);
      this.$https
        .put(
          "/api/my/course/liveRecord/" + this.$route.query.id + "/" + item.id
        )
        .then((res) => {
          console.log("liveRecord");
          console.log(res);
        })
        .catch((err) => {
          console.log("liveRecord");
          console.log(err);
        });
    },
    // 视频
    viewViedo(item) {
      this.playerPlaying = false;
      this.currentPlayTimer = 0;
      this.currentPlayStatus = false;
      this.videoType = "video";
      this.activelives = null;
      this.viewTitle = item.title;
      this.actvieVideoSections = item.sectionId;
      this.activeVideo = item.id;
      this.playerOptions.sources = [
        {
          type: "video/mp4",
          src: this.httpsLocation + item.vedio.url,
        },
      ];
    },
    // 判断是否有直播数据
    judeLives(res, item) {
      let isAlive = false;
      const startTime = moment(
        moment().format("YYYY-MM-DD") + " " + item.startTime + ":00"
      ).valueOf();
      const endTime = moment(
        moment().format("YYYY-MM-DD") + " " + item.endTime + ":00"
      ).valueOf();
      let aliveDate;
      // 判断日期
      Object.keys(item.dateSettings).forEach((el) => {
        let target = item.dateSettings[el];
        if (target) {
          switch (el) {
            case "monthDays":
              if (target.includes(moment(parseInt(res.stime)).format("DD"))) {
                isAlive = true;
              }
              aliveDate =
                "每月" +
                target.map((el) => el + 1).join(",") +
                "号 " +
                item.startTime +
                " 开始";
              break;
            case "specDays":
              target = target.map((el) =>
                moment(parseInt(el)).format("YYYY-MM-DD")
              );
              if (
                target.includes(
                  moment(parseInt(res.stime)).format("YYYY-MM-DD")
                )
              ) {
                isAlive = true;
              }
              aliveDate = target.join(",") + " " + item.startTime + " 开始";
              break;
            case "weeklyDays":
              if (target.includes(moment(parseInt(res.stime)).day())) {
                isAlive = true;
              }
              aliveDate =
                "每周" +
                target.map((el) => getWeekDate(el)).join(",") +
                " " +
                item.startTime +
                " 开始";
              break;
          }
        }
      });
      // 判断时间
      if (isAlive) {
        if (!(res.stime >= startTime && res.stime <= endTime)) {
          isAlive = false;
        }
      }
      // 计算直播播放时间,直播开始时间减去当前服务器时间得到已经开始的时间
      let currentTime = (res.stime - startTime) / 1000;
      let _currentTime = 0;
      let timeLength = 0;
      let livingIndex = 0;
      if (isAlive) {
        for (let i = 0; i <= item.vedios.length; i++) {
          if (timeLength >= currentTime) {
            livingIndex = i;
            _currentTime =
              item.vedios[i - 1].timeLength - (timeLength - currentTime);
            break;
          }
          timeLength += item.vedios[i].timeLength;
        }
      }
      return {
        currentTime: _currentTime,
        living: isAlive,
        // living: true,
        livingIndex: livingIndex ? livingIndex - 1 : 0,
        aliveDate: aliveDate,
        stime: res.stime,
        ajaxTime: moment().valueOf(),
      };
    },
    setVidoData(options, callBack, isSet = false) {
      const userInfo =
        (sessionStorage.getItem("userInfo") &&
          JSON.parse(sessionStorage.getItem("userInfo"))) ||
        {};
      // 如果是直接不保存数据
      if (this.videoType == "liveing") {
        return false;
      }
      // 保存当前数据到本地（用于保存回显视频地址和位置）
      let list = this.$storage.getStorage("vidoDataList") || [];
      // 2.929275
      // let listIndex = list.findIndex(
      //   (p) =>
      //     Number(p.detailsID) === Number(options.detailsID) &&
      //     Number(p.activeVideoID) === Number(options.activeVideoID) &&
      //     userInfo.userName == p.userName
      // );
      let listIndex = -1;
      listIndex = list.findIndex(
        (p) =>
          Number(p.detailsID) === Number(options.detailsID) &&
          Number(p.activeVideoID) === Number(options.activeVideoID) &&
          userInfo.userName == p.userName
      );
      // 兼容历史数据没有userName
      if (listIndex == -1) {
        listIndex = list.findIndex(
          (p) =>
            Number(p.detailsID) === Number(options.detailsID) &&
            Number(p.activeVideoID) === Number(options.activeVideoID) &&
            !p.userName
        );
      }
      console.log(listIndex)
      if (listIndex !== -1) {
        if (callBack) {
          callBack([...list], listIndex);
        }
        if (isSet) {
          list[listIndex] = {
            detailsID: Number(options.detailsID),
            activeVideoID: Number(options.activeVideoID),
            time: options.time != null ? options.time : list[listIndex].time,
            timeLength: options.timeLength || 0,
            userName: userInfo.userName,
          };
        }
      } else {
        list.push({
          detailsID: Number(options.detailsID),
          activeVideoID: Number(options.activeVideoID),
          time: options.time || 0,
          timeLength: options.timeLength || 0,
          userName: userInfo.userName,
        });
      }
      this.$storage.setStorage([
        {
          key: "vidoDataList",
          value: list,
        },
      ]);
    },
  },
};

function getWeekDate(date) {
  const list = [
    { name: "星期日", value: 0 },
    { name: "星期一", value: 1 },
    { name: "星期二", value: 2 },
    { name: "星期三", value: 3 },
    { name: "星期四", value: 4 },
    { name: "星期五", value: 5 },
    { name: "星期六", value: 6 },
  ];
  return list.find((el) => el.value == date).name;
}
</script>
<style lang="less">
.curriculumDetails {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #fff;
  // overflow: hidden;
  // overflow: hidden;
  .media {
    position: absolute;
    left: 0;
    right: 350px;
    top: 0;
    bottom: 0;
    border: 1px solid #e4e7ed;
    .title {
      color: #677897;
      font-size: 16px;
      font-weight: bold;
      padding: 10px;
      height: 36px;
    }
    .title2 {
      background: #d4d8df;
      text-align: center;
      color: #da2828;
      font-size: 16px;
      font-weight: bold;
      padding: 10px;
    }
    .vjs-progress-control {
      display: none !important;
    }
    .vjs-fullscreen-control {
      position: absolute;
      right: 0;
    }
    .aliving {
      .vjs-play-control {
        display: none !important;
      }
      .vjs-current-time {
        display: none !important;
      }
      .vjs-tech {
        pointer-events: none;
      }
      .timeout-lives {
        text-align: center;
      }
    }
  }
  .media-list {
    position: absolute;
    width: 350px;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 20px;
    border-top: 1px solid #e4e7ed;
    font-size: 13px;
    overflow: auto;
    .video-item {
      cursor: pointer;
      line-height: 30px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px solid #e4e7ed;
      font-weight: normal;
      color: #b0b9ca;
      .video-item-list {
        padding-left: 10px;
      }
      .main-title {
        width: 75%;
        float: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }
      .timer {
        width: 25%;
        text-align: right;
        float: right;
      }
    }
    .ailve-item {
      cursor: pointer;
      line-height: 30px;
      border-bottom: 1px solid #e4e7ed;
      color: #b0b9ca;
      font-weight: bold;
      font-size: 14px;
      .description {
        font-size: 12px;
        font-weight: normal;
      }
    }
    .active {
      color: #0a73c5;
      .description {
        color: #677897;
      }
    }

    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__content {
      padding: 10px 0;
    }
    .el-tabs__item {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
    }
    .el-collapse-item__header,
    .el-collapse-item__wrap,
    .el-collapse {
      border: none;
      color: #677897;
      font-weight: bold;
    }
  }
}
</style>
